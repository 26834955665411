.ListFiles {
  margin-top: 30px;

  .title-line {
    display: flex;
    justify-content: space-between;
  }
  .download-button {
    align-items: normal;
    background-color: rgba(0,0,0,0);
    border-color: rgb(0, 0, 238);
    border-style: none;
    box-sizing: content-box;
    color: #1677ff;
    cursor: pointer;
    display: inline;
    font: inherit;
    height: auto;
    padding: 0;
    perspective-origin: 0 0;
    text-align: start;
    transform-origin: 0 0;
    width: auto;
    -moz-appearance: none;
    -webkit-logical-height: 1em; /* Chrome ignores auto, so we have to use this hack to set the correct height  */
    -webkit-logical-width: auto; /* Chrome ignores auto, but here for completeness */
  }
  .download-button:hover { /* add outline to focus pseudo-class */
    color: #69b1ff;
  }
}
