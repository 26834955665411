.AccessWrapper {
  display: flex;
  height: 100vh;
  background-image: linear-gradient(to right, #000c17,#001529, #02284d, #033566);

  .logo-container {
    flex: 7;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .children-container {
    flex: 4;
  }
}

@media (max-width: 700px) {
  .AccessWrapper {
    flex-direction: column;
    .logo-container {
      flex: 1;
    }
    .children-container {
      flex: 1;
    }
  }
}

