.ModalLogin {
  .ant-modal-body {
    padding: 30px 0 0 0;

    .logo-container{
      padding: 0 0 40px 0;
      text-align: center;
      .logo-image{
        width: 115px;
      }
    }
    .login-btn {
      text-align: right;
    }
  }
}