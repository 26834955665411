.PackageGenerator {
  padding-top: 15px;
  padding-bottom: 100px;
  .line-full {
    display: flex;
    justify-content: space-between;
    @media (max-width: 700px) {
      display: block;
    }
  }
  .line-half-container {
    display: flex;
    justify-content: space-between;
    @media (max-width: 850px) {
      display: block;
    }
  }
  .line-half {
    display: flex;
//     justify-content: space-between;
    flex: 1;
    .ant-form-item: {
      flex: 1 !important;
    }
    .select {
      width: 180px;
    }
  }

  .form-actions {
    margin: 20px 0 50px 0;
    display: flex;
    justify-content: flex-end;
    gap: 30px;
    flex-wrap: wrap;
  }

}

body.dark-theme {
    background-color: #060f2f;
    color: #ccc;
}