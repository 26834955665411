.Login{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-form-container {
    width: 90%;
    max-width: 340px;

    button {
      width: 100%;
    }
  }

}
