.PrivatePageWrapper {
//   background-color: #282c34;
  height: 100vh;
  width: 100vw;
  display: flex;
  position: fixed;

  .menu {
    height: 100vh;
    background-color: #000c17;
    overflow: auto;
    min-width: 100px;
    ul {
      width: 100%;
    }
    &.full {
      min-width: 220px;
    }
    @media (max-width: 700px) {
      min-width: unset;
      ul {
        width: unset;
      }
    }
  }


  .ant-menu-item-group-title {
    text-align: center;
    min-width: 80px;
  }

  .logo {
    height: 35px;
    margin: 5px;
    text-align: center;
  }

  .page-container {
    width: 100%;
    box-sizing: border-box;
    overflow: auto;


    .page-header {
      position: relative;
      padding: 0 6px 6px 6px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      height: 40px !important;
      z-index: 10;
      line-height: 48px;
      background-color: #fafafa;
      -webkit-box-shadow: 0 4px 2px -1px rgba(0,0,0,.2);
      -moz-box-shadow: 0 4px 2px -1px rgba(0,0,0,.2);
      box-shadow: 0 4px 2px -1px rgba(0,0,0,.2);

      .page-header-left {
        display: flex;

        .btn-menu-toggle {
          margin-top: 9px;
        }

        .page-title {
          display: block;
          font-size: 1.17em;
          font-weight: bold;
        }
      }

      .page-header-right {

      }

    }

    .page-content {
      height: calc(100vh - 46px);
      overflow: auto;
      box-sizing: border-box;
      padding: 0 20px;
    }
  }

  @media (max-width: 700px) {
    .hidden-mobile {
      display: none;
    }
  }

  &.dark-theme {
    .page-content {
//       background-color: #3E4652;
//       color: #ccc;
    }
  }
}


// ----------custom content scrollbar-------------
/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #808080;
  border-radius: 10px;
  background: #e6e6e6;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #a7a7a7;
  border-radius: 50px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8e8e8e;
}
// ----------custom content scrollbar-------------


// disable text selection highlighting
.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}