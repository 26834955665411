.MercadosGlobaisForm {
  .line {
    display: flex;
    justify-content: space-between;
    .ant-form-item {
      flex: 1;
    }
    .select {
      width: 150px;
    }
  }
}
