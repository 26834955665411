.GerenciarAssessores {
  margin-top: 20px;

  .ant-form-item-explain {
    margin-bottom: 15px;
  }

  .upload-container{
    max-width: 500px;
  }
}