.TesouroDiretoForm {
  .line{
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    .anticon-minus-circle {
      height: 30px;
      margin-left: 8px;
    }
  }

}
