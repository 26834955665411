.UploadImage {
  margin-bottom: 60px;

  .upload-container {
    display: flex;
    justify-content: space-between;
  }

  .upload-component {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }

  .ant-upload-list-item {
    height: 110px !important;
  }

  .ant-upload-list-item-thumbnail{
    width: 100px !important;
    height: 100px !important;
  }

  .ant-upload-list-item-name {
//     display: none;
  }

  .undo-delete {
    display: block;
    text-align: center;
    .info-container {
      margin-top: 8px;
      svg {
        margin-right: 5px;
      }
    }
  }

  @media (max-width: 700px) {
    .upload-component {
      display: block;
    }
    .undo-delete {
      margin-top: 15px;
      text-align: left;
    }
  }
}