.TemporadaBalancosForm {
  .line{
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    .ant-form-item {
      flex: 1;
    }
    .anticon-minus-circle {
      height: 30px;
      margin-left: 8px;
    }
  }

}
