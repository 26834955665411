.ListarAssessores {
  margin-bottom: 100px;

  .header {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;

    .header-total {
      .total-item {
        margin: 3px 0;
      }
    }
    .add-new-container {
      text-align: right;
    }

  }

  .filter-form-container {
    border-top: 1px solid #ccc;
    padding: 20px 0;
    border-bottom: 1px solid #ccc;
    margin: 30px 0;

    .filter-form {
      margin: 10px 0 5px 0;
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }
  }
}