.FormDraftList {
  margin: 20px 0;
  .preview-container {
    .models-container {
      .ant-radio-group {
        margin-top: 0;
      }
    }
    .actions-container {
      display: flex;
      gap: 10px;
      margin-bottom: 25px;
    }
    .img-container {
      max-width: 500px;
      width: 100%;
      margin: 0 auto;
      img {
        max-height: 90vh;
        max-width: 100%;
      }
    }
  }
}
