.JornalForm {
  .select {
    width: 200px;
    max-width: 100%;
  }

  .line{
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

  }
}
